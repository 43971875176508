<template>
  <div class="internal-sharing">
    <div class="px-3">
      <div class="pb-4 mb-4 border-b-grey">
        <link-container
          :url="callUrl"
        />
      </div>
      <div class="share-with mb-3">
        <BaoVueMultiSelect
          v-model="selectedUsers"
          :options="teamMembers"
          :show-labels="false"
          :closeOnSelect="true"
          :track-by="'email'"
          :label="'full_name'"
          :placeholder="staticText.selectUserLabel"
          :multiple="true"
        >
          <template #placeholder>
            <div
              v-show="!selectedUsers.length"
              class="d-flex multiselect__single">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.95 7.3a2.25 2.25 0 10.001-4.498 2.25 2.25 0 00-.001 4.499zM4.6 7.3a2.25 2.25 0 10.001-4.498A2.25 2.25 0 004.6 7.301zM19 11.203C19 9.545 17.656 8.2 16 8.2h-1.202c-.447 0-.872.098-1.254.273A3.601 3.601 0 0112.38 11.8h6.02c.33 0 .6-.27.6-.6zM7.6 11.8h.02a3.591 3.591 0 01-1.217-2.7c0-.214.02-.422.053-.627A2.974 2.974 0 005.202 8.2H4C2.344 8.2 1 9.545 1 11.2c0 .333.27.6.6.6h6zm-.3-2.7a2.7 2.7 0 105.401-.002 2.7 2.7 0 00-5.401.002zm8.1 7.349a3.75 3.75 0 00-3.75-3.75h-3.3a3.75 3.75 0 00-3.75 3.75.75.75 0 00.75.75h9.3a.75.75 0 00.75-.75z" fill="#555775"/></svg>
              <span class="ml-2 link-color">
                {{ staticText.selectUserLabel }}
              </span>
            </div>
          </template>
          <template #selection="{ values, isOpen }">
            <div v-show="!isOpen && values.length">
              <div class="d-flex multiselect__single">
                <img src="@/assets/svgs/participants.svg" svg-inline>
                <span class="ml-2 link-color">
                  {{staticText.selectUserLabel}}
                </span>
              </div>
            </div>
          </template>
        </BaoVueMultiSelect>
      </div>
      <div
        v-if="selectedUsers && selectedUsers.length"
        class="my-3 d-flex flex-column"
      >
        <span class="share-with-label mb-4">
          {{staticText.shareWithLabel}}
        </span>
        <div class="d-flex shared-with">
          <bao-pill
            v-for="(item, idx) in selectedUsers"
            :key="idx"
            :title="item.full_name"
            :id="item.email"
            @close="removeItem($event)"
          />
        </div>
      </div>
    </div>
    <div class="internal-sharing__footer">
      <b-button
        class="btn btn-primary mr-3"
        size="sm"
        @click="closeShareModal"
      >
        {{staticText.cancelShareLabel}}
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        :disabled="!checkForObjPermissionChanges"
        @click="shareCall"
      >
        {{staticText.saveShareLabel}}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import BaoVueMultiSelect from "@/apps/base/BaoVueMultiSelect"
import BaoPill from "@/apps/base/BaoPill"
import LinkContainer from "./LinkContainer.vue"

export default {
  name: "InternalCallSharing",
  data () {
    return {
      sharedWithUsers: [],
      showModal: true,
      teamMembers: [],
      selectedUsers: [],
      staticTextDefault: {
        selectUserLabel: "Select User",
        cancelShareLabel: "Cancel",
        saveShareLabel: "Save",
        shareWithLabel: "Shared With"
      }
    }
  },
  components: {
    BaoVueMultiSelect,
    BaoPill,
    LinkContainer
  },
  mounted () {
    this.getGroupMembers()
    this.sharedWithUsers = this.getSelectedUsers()
    this.selectedUsers = this.sharedWithUsers
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/user",
      callDetails: "callSummaryStore/getCallDetails"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    callId () {
      return Number(this.$route.params.id)
    },
    callUrl () {
      const fullCallUrl = window.location.href
      // truncate the url if it exceed 50 characters. on sharing call, some utm params are added and that makes the url length long, so we need to truncate there
      if (fullCallUrl.length >= 50) {
        return fullCallUrl.substr(0, 50) + "..."
      }
      return fullCallUrl
    },
    checkForObjPermissionChanges () {
      const sharedWithUserIds = this.sharedWithUsers.map(user => user.pk)
      const newShareWithUserIds = this.selectedUsers.map(user => user.pk)
      const grantPermissionTo = this.selectedUsers.filter(user => !sharedWithUserIds.includes(user.pk))
      const revokePermissionFrom = this.sharedWithUsers.filter(user => !newShareWithUserIds.includes(user.pk))
      return Boolean(grantPermissionTo.length || revokePermissionFrom.length)
    }
  },
  methods: {
    ...mapActions({
      fetchCallDetails: "callSummaryStore/fetchCallDetails"
    }),
    closeShareModal () {
      this.$emit("close-modal")
    },
    getSelectedUsers () {
      return this.callDetails.shared_with.users
        ? this.callDetails.shared_with.users.filter(
          user => user.pk !== this.currentUser.pk && user.is_admin === false
        )
        : []
    },
    async shareCall () {
      const sharedWithUserIds = this.sharedWithUsers.map(user => user.pk)
      const newShareWithUserIds = this.selectedUsers.map(user => user.pk)
      const grantPermissionToids = this.selectedUsers.filter(user => !sharedWithUserIds.includes(user.pk)).map(user => user.pk)
      const revokePermissionFromIds = this.sharedWithUsers.filter(user => !newShareWithUserIds.includes(user.pk)).map(user => user.pk)
      const data = {
        grant_perm_to_user_ids: grantPermissionToids,
        remove_perm_from_user_ids: revokePermissionFromIds,
        call_url: window.location.href
      }
      try {
        await axios.put(`/api/calls/${this.callId}/share_internally`, data)
      } catch (err) {
        this.showGlobalToast({
          status: "error",
          message: { title: "Something went wrong", description: "Error in sharing the call data." }
        })
      }
      // get the refreshed call data
      await this.fetchCallDetails({ callId: this.callId })
      this.showModal = false
    },
    removeItem (data) {
      this.selectedUsers = this.selectedUsers.filter(item => item.email !== data)
    },
    async getGroupMembers () {
      const url = `/api/users/get_group_members?is_active=true&id!=${this.currentUser.pk}`
      const response = await axios.get(url)
      this.teamMembers = response.data && response.data.results ? response.data.results : response.data
      this.teamMembers = this.teamMembers.filter(user => user.is_admin === false)
    }
  }
}
</script>

<style lang="scss" scoped>

.share-with{
  box-shadow: 4px 4px 20px 0px #0000001A;
  border-radius: 12px;
}

.shared-with{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.share-with-label{
  color: #7F8197;
}

.link-color{
  color: #2A2D52
}

.copy-btn{
  svg {
    path {
      fill: #AAABBA;
    }
  }
}

.border-b-grey {
  border-bottom: 1px solid #F0F0F0;
}

.internal-sharing {
  padding: 16px 0;
  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px 0 0;
    border-top: 1px solid #dee2e6;
  }
}

</style>
