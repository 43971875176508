<template>
  <div class="link-sharing-tab">
    <link-container
      :url="url"
      :show-copy-button="false"
    />
    <share-action-button
      :action-label="getLabel"
      :password="password"
      :show-success-message="showCopiedMessage"
      :is-loading="isLoading"
      :is-disabled="isDisabled"
      @trigger-action="copyLinkAndPassword"
    />
  </div>
</template>

<script>
import LinkContainer from "./LinkContainer.vue"
import ShareActionButton from "./ShareActionButton.vue"

export default {
  name: "LinkSharingTab",
  components: { LinkContainer, ShareActionButton },
  data () {
    return {
      showCopiedMessage: false,
      isLoading: false,
      staticTextDefault: {
        copyLinkAndPassword: "Copy Link & Password",
        linkAndPasswordCopied: "Link & Password copied!",
        passwordLabel: "Password"
      }
    }
  },
  props: {
    password: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    hasPasswordError: {
      type: Boolean
    },
    isUserChangingPassword: {
      type: Boolean
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getLabel () {
      return this.showCopiedMessage ? this.staticText.linkAndPasswordCopied : this.staticText.copyLinkAndPassword
    },
    isDisabled () {
      return !this.password || this.isLoading || this.hasPasswordError
    }
  },
  methods: {
    async copyLinkAndPassword () {
      if (this.password) {
        this.isLoading = true
        // If user is currently changing password, we wait till it's done before copying the password
        while (this.isUserChangingPassword) await this.sleep(100)
        this.isLoading = false
        if (!this.hasPasswordError && navigator && navigator.clipboard) {
          const textToCopy = `\nURL: ${this.url}\n\n${this.staticText.passwordLabel}: ${this.password}\n`
          navigator.clipboard.writeText(textToCopy)
          this.showCopiedMessage = true
          await this.sleep(2000)
          this.showCopiedMessage = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.link-sharing-tab {
  padding-top: 16px;
}

</style>
